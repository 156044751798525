<template>
	<div :class="`${showManageSubscription ? '' : 'app flex-row align-items-center'}`">
		<div v-if="vueAppRunningProduction" class="banner-testing-mode"></div>
		<div v-if="vueAppRunningProduction" class="container-testing-mode">
			{{ FormMSG(268, 'Testing') }}
		</div>
		<div class="container">
			<div v-if="!forgotPassword && !showManageSubscription">
				<b-row v-if="showSuggestChrome" class="justify-content-center">
					<b-col md="8">
						<b-alert variant="warning" show dismissible>
							{{ FormMSG(250, 'Use') }} <strong>{{ FormMSG(251, 'Chrome browser') }}</strong> {{ FormMSG(252, 'for better use') }}.
						</b-alert>
					</b-col>
				</b-row>
				<b-row class="login justify-content-center">
					<b-col md="8" class="form">
						<!-- <loading :active.sync="isLoading" :is-full-page="true" /> -->
						<b-card-group>
							<b-card
								no-body
								class="card-border-blue-light p-4"
								:class="`${!$screen.xl && !$screen.lg ? 'card-full-fill' : 'card-half-left-fill'}`"
							>
								<b-card-body>
									<div class="text-center">
										<img class="navbar-brand-full" src="img/brand/thegreenshot-brand.png" width="225" alt="TheGreenShot" />
										<h2 class="pt-3 pb-2">{{ FormMSG(101, ' Login') }}</h2>
										<!-- <p class="text-muted">
											{{ FormMSG(102, 'Sign In to your account') }}
										</p> -->
									</div>
									<b-input-group class="mb-3">
										<b-input-group-prepend>
											<b-input-group-text>
												<component :is="getLucideIcon('User')" :color="ICONS.PERCENT.color" :size="18" />
											</b-input-group-text>
										</b-input-group-prepend>
										<b-form-input
											v-model="$v.email.$model"
											type="email"
											:disabled="askNewPassword"
											class="form-control"
											:class="{ 'border-gray': $v.email.$error === false, 'br-none': $v.email.$error === true }"
											:placeholder="FormMSG(103, 'Email address')"
											autocomplete="username email"
											:state="!$v.email.$error"
											@keyup.enter="handleKeyUpEnterEmail"
										/>
										<b-input-group-append v-if="$v.email.$error">
											<button
												class="btn-show-password border-red"
												type="button"
												v-b-tooltip.click.bottomleft="manageMessageEmailError($v.email)"
											>
												<AlertTriangle color="red" :size="16" />
											</button>
										</b-input-group-append>
									</b-input-group>
									<p v-if="askNewPassword" class="text-muted">
										{{ FormMSG(1111, 'New password') }}
									</p>
									<b-input-group class="mb-4">
										<b-input-group-prepend>
											<b-input-group-text>
												<component :is="getLucideIcon('Lock')" :color="ICONS.PERCENT.color" :size="18" />
											</b-input-group-text>
										</b-input-group-prepend>
										<b-form-input
											v-model="$v.password.$model"
											:type="showPassword ? 'text' : 'password'"
											class="form-control br-none"
											:class="{ 'border-gray br-none': $v.password.$error === false }"
											:placeholder="FormMSG(104, 'Password')"
											autocomplete="current-password"
											:state="!$v.password.$error"
											@keyup.enter="handleKeyUpEnterPassword"
										/>
										<b-input-group-append>
											<button class="btn-show-password" type="button" @click="showPassword = !showPassword">
												<component v-if="!showPassword" :is="getLucideIcon('Eye')" :color="ICONS.PERCENT.color" :size="18" />
												<component v-if="showPassword" :is="getLucideIcon('EyeOff')" :color="ICONS.PERCENT.color" :size="18" />
											</button>
											<button
												v-if="$v.password.$error === true"
												class="btn-show-password border-red"
												style="border-left: none !important"
												type="button"
												v-b-tooltip.click.bottomleft="FormMSG(498, 'Password is required')"
											>
												<AlertTriangle color="red" :size="16" />
											</button>
										</b-input-group-append>
									</b-input-group>
									<div v-if="askNewPassword">
										<p v-if="askNewPassword" class="text-muted">
											{{ FormMSG(1112, 'Password verification') }}
										</p>
										<b-input-group class="mb-4">
											<b-input-group-prepend>
												<b-input-group-text>
													<component :is="getLucideIcon('Lock')" :color="ICONS.PERCENT.color" :size="18" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="passwordVerification"
												:type="showPasswordVerification ? 'text' : 'password'"
												class="form-control br-none"
												:placeholder="FormMSG(1113, 'Re-enter Password')"
												autocomplete="current-password"
											/>
											<b-input-group-append>
												<button class="btn-show-password" type="button" @click="showPasswordVerification = !showPasswordVerification">
													<component
														v-if="!showPasswordVerification"
														:is="getLucideIcon('Eye')"
														:color="ICONS.PERCENT.color"
														:size="18"
													/>
													<component
														v-if="showPasswordVerification"
														:is="getLucideIcon('EyeOff')"
														:color="ICONS.PERCENT.color"
														:size="18"
													/>
												</button>
											</b-input-group-append>
										</b-input-group>
									</div>
									<div v-if="askNewPassword && queryType.NU" class="mb-3">
										<b-form-checkbox :value="true" :unchecked-value="false" @change="handleChangeCheckTermsOfService">
											{{ FormMSG(81, 'I accept') }}
											<span class="false-link" @click="handleClickTermsOfService">{{
												FormMSG(82, "TheGreenshot's Terms of Service")
											}}</span>
										</b-form-checkbox>
									</div>
									<b-row>
										<b-col md="12">
											<b-button
												style="width: 100%"
												type="submit"
												variant="primary"
												class="px-4"
												@click="signin"
												:disabled="loginDisabled() || waitProcessSignin"
											>
												<div class="d-flex justify-content-center align-items-center">
													<b-spinner v-if="waitProcessSignin" label="Loading..." small></b-spinner>
													<div class="pl-2" style="margin-top: 1px">{{ FormMSG(112, 'Login') }}</div>
												</div>
											</b-button>
										</b-col>
										<!-- <b-col v-if="showRegisterOption" md="12" class="d-lg-none">
											<b-button style="width: 100%" class="active mt-3" variant="secondary" @click="registerNow">
												{{ FormMSG(107, 'Register Now!') }}
											</b-button>
										</b-col> -->
										<b-col v-if="!askNewPassword" class="text-right" md="12">
											<b-button
												style="width: 100%"
												variant="link"
												class="px-0"
												:disabled="waitProcessSignin"
												@click="handleClickForgotPassword"
											>
												{{ FormMSG(105, 'Forgot password?') }}
											</b-button>
										</b-col>
									</b-row>
									<!-- </b-form> -->
								</b-card-body>
							</b-card>
							<!-- <b-card
								v-if="showRegisterOption"
								no-body
								class="card-border-blue-light text-white py-5 d-md-down-none"
								style="width: 44%; border-radius: 0 8px 8px 0 !important; background-color: rgba(0, 160, 156, 0.84) !important"
							>
								<b-card-body class="text-center">
									<div style="letter-spacing: 0.05rem">
										<h2 class="pb-2">{{ FormMSG(111, 'Sign up') }}</h2>
										<p>
											{{
												FormMSG(
													106,
													'Sign up now to join and start working with TheGreenShot, track your timesheets, expenses, travels... and your carbon footprint.'
												)
											}}
										</p>
										<b-button variant="primary" class="active mt-3 px-4" :disabled="waitProcessSignin" @click="registerNow">
											{{ FormMSG(107, 'Register Now!') }}
										</b-button>
									</div>
								</b-card-body>
							</b-card> -->
						</b-card-group>
					</b-col>
					<b-modal
						header-class="header-class-modal-doc-package"
						v-model="successModal"
						class="modal-success"
						ok-variant="success"
						ok-only
						:title="FormMSG(108, 'Success!')"
						@ok="successModal = false"
						modal-class="mui-animation"
						:fade="false"
					>
						{{ FormMSG(109, '  An email has been sent to you. Open it and click on the provided link to reset your password.') }}
					</b-modal>
					<b-modal
						header-class="header-class-modal-doc-package"
						v-model="showModalSuccessRegistration"
						class="modal-success"
						ok-variant="success"
						ok-only
						no-close-on-backdrop
						no-close-on-esc
						:title="FormMSG(108, 'Success!')"
						@ok="handleOkModalSuccessRegistration"
						@hidden="handleOkModalSuccessRegistration"
						modal-class="mui-animation"
						:fade="false"
					>
						<p>
							{{ messageModalSuccessRegistration }}
						</p>
					</b-modal>
					<b-modal
						header-class="header-class-modal-doc-package"
						v-model="showSubscriptionNotOrder"
						class="modal-danger"
						ok-variant="danger"
						ok-only
						no-close-on-backdrop
						no-close-on-esc
						:title="FormMSG(140, 'Subscription Error!')"
						@ok="showSubscriptionNotOrder = false"
						@hidden="showSubscriptionNotOrder = false"
						modal-class="mui-animation"
						:fade="false"
					>
						<p>
							{{
								FormMSG(
									141,
									'Your subscription is not in order, please call support to verify. All Solo+ projects are deactivated and your account is changed to Solo Free until your subscription returns to normal.'
								)
							}}
						</p>
					</b-modal>
				</b-row>
			</div>
			<div v-if="forgotPassword">
				<account-recovery @account-recovery:next-step="handleAccountRecoveryNextStep" />
			</div>
			<error-app-version v-model="isErrorAppVersion" :is-home="false" :last-version="version" @error-app-version-modal:closed="onErrorAppVersionClosed" />
		</div>
	</div>
</template>

<script>
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import LanguageMessages from '@/mixins/languageMessages';
import { getParamVersion } from '@/shared/version';
import { getUserRequestInfo, getNewPasswordRequestInfo } from '@/cruds/users.crud';
import { isNil } from '~utils';
import { Eye, EyeOff, AlertTriangle } from 'lucide-vue';
import AccountRecovery from '@/components/Login/AccountRecovery';
import SuggestChromeMixin from '@/mixins/suggestChrome.mixin';
import { getUserLastProject } from '@/cruds/project.crud';
import { getLicenseRequest } from '@/cruds/licenseRequest.crud';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import GlobalMixin from '@/mixins/global.mixin';
import { getValidations } from '@/cruds/validations.crud';
import ErrorAppVersion from '@/components/Modals/ErrorAppVersionModal';
import { signinService } from '@/cruds/registration.crud';
import { DEVICE } from '@/shared/constants';
import { HttpValidation } from '@/helpers/HttpValidation';
import { isShowFimalacPlanning } from '@/router';

export default {
	name: 'Login',
	mixins: [LanguageMessages, SuggestChromeMixin, validationMixin, GlobalMixin],
	components: {
		Loading,
		Eye,
		EyeOff,
		AlertTriangle,
		AccountRecovery,
		ErrorAppVersion
		// VueCroppie
	},
	data() {
		return {
			isLoading: false,
			successModal: false,
			startSoloModal: false,
			email: '',
			urlXid: null,
			askNewPassword: false,
			showRegisterOption: true,
			password: '',
			passwordVerification: '',
			termsOfService: false,
			userRequest: {
				userName: '',
				userFirstName: '',
				email: '',
				userId: 0,
				validated: 0,
				xid: ''
			},
			licenseRequest: {
				userName: '',
				userFirstName: '',
				email: '',
				userId: 0,
				validated: 0,
				xid: '',
				requestType: -1
			},
			showModalSuccessRegistration: false,
			messageModalSuccessRegistration: '',
			queryType: {
				NL: false,
				NP: false,
				NU: false
			},

			showPassword: false,
			showPasswordVerification: false,

			forgotPassword: false,
			showSubscriptionNotOrder: false,

			showManageSubscription: false,
			projectForSubscription: null,
			myProfileForSubscription: {
				id: 0,
				name: '',
				firstName: '',
				email: '',
				language: 0
			},

			waitProcessSignin: false,
			requiredAppVersion: null,
			isErrorAppVersion: false,
			version: '',
			countAppVersionErrorLoaded: 0,
			DEVICE
		};
	},

	validations: {
		email: { required, email },
		password: { required }
	},

	async mounted() {
		const init = await store.onLoad().then(async (res) => {
			if (res) {
				await isShowFimalacPlanning();
				if (this.$cookies.isKey('emailRegister')) {
					this.email = this.$cookies.get('emailRegister');
				}

				// check if paramater present and get userRequest information.
				let validToken;
				const paramLoaded = await this.checkUrlParameters();

				if (paramLoaded) {
					validToken = false;
				} else {
					validToken = store.isTokenValid();
				}
				const requiredAppVersion = this.$store.getters['header/getRequiredAppVersion'];
				if (validToken === true) {
					this.requiredAppVersion = null;

					if (isNil(requiredAppVersion)) {
						const isFimalac = await this.redicrectFimalac();
						this.$store.dispatch('header/setErrorAppVersion', null);
						this.$router
							.push('/my-dashboard' + getParamVersion())
							.then(async (res) => {
								if (res) {
									return new Promise((resolve, _) => resolve(HttpValidation.status)).then((httpStatus) => {
										if (httpStatus) {
											const useFinance = store.state.myProject.useFinance;
											if (isFimalac && useFinance && httpStatus) {
												this.$router.push('/time-cards/planning').catch(() => {});
											}
											return httpStatus;
										}
									});
								}
							})
							.catch((error) => {
								throw new Error(error);
							});
					} else {
						this.version = requiredAppVersion;
						this.requiredAppVersion = requiredAppVersion;
						// this.$router.go(0);
						this.isErrorAppVersion = true;
						if (this.detectDevice === DEVICE.WEB) {
							this.$router.replace(`/pages/login?${getParamVersion()}`).then((res) => {
								return res;
							});
							this.$store.dispatch('header/setCountAppVersionErrorLoaded');
						}
					}
				} else {
					if (!isNil(requiredAppVersion)) {
						this.version = requiredAppVersion;
						this.requiredAppVersion = requiredAppVersion;
						// this.$router.go(0);
						this.isErrorAppVersion = true;
						if (this.detectDevice === DEVICE.WEB) {
							this.$router
								.replace(`/pages/login?${getParamVersion()}`)
								.then((res) => {
									return res;
								})
								.catch((err) => err);
							this.$store.dispatch('header/setCountAppVersionErrorLoaded');
						}
					}
				}
				/**
				 * Pour tester si le logout a été forcé pour cause d'erreur d'abonnement
				 * C'est dans le defaultContainer qu'il y a l'écoute de l'abonnement
				 */
				if (!validToken) {
					if (this.getNavItems[0]) await this.mutateNavItems({ isUpdate: false, navs: [] });
					const currentProject = store.getCurrentProjectConfig();
					if (currentProject) localStorage.removeItem('current-project');
					const savedNoTranslation = store.getSavedNoTranslationMenus();
					if (savedNoTranslation) localStorage.removeItem('saved-no-trans-menus');
				}
				if (store.state.subscriptionNotOrder) {
					this.showSubscriptionNotOrder = true;
					delete store.state.subscriptionNotOrder;
				}
				return res;
			}
		});
		return init;
	},

	computed: {
		...mapGetters({
			getSubscriptionViaLogin: 'subscription/getSubscriptionViaLogin',
			getSubscriptionLicenseRequest: 'subscription/getSubscriptionLicenseRequest',
			getNavItems: 'nav-items/getNavItems'
		}),
		vueAppRunningProduction() {
			return process.env.VUE_APP_RUNNING_MODE !== 'PROD';
		},
		detectDevice() {
			let device = DEVICE.WEB;

			if (this.$isIOSDevice()) {
				device = DEVICE.IOS;
			} else if (this.$isAndroidDevice()) {
				device = DEVICE.ANDROID;
			}

			return device;
		}
	},

	methods: {
		...mapActions({
			updateSubscriptionViaLogin: 'subscription/updateSubscriptionViaLogin',
			updateSubscriptionLicenseRequest: 'subscription/updateSubscriptionLicenseRequest',
			resetSubscriptionData: 'subscription/resetSubscriptionData',
			updateSubscriptionProjectId: 'subscription/updateSubscriptionProjectId',
			mutateNavItems: 'nav-items/mutateNavItems'
		}),
		manageMessageEmailError(emailValidation) {
			if (emailValidation.email === false) {
				return this.FormMSG(458, 'Email not valid');
			}
			if (emailValidation.required === false) {
				return this.FormMSG(459, 'Email is required');
			}
		},
		async handleKeyUpEnterEmail() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.signin();
			}
		},
		handleKeyUpEnterPassword() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.signin();
			}
		},
		handleClickTermsOfService() {
			this.termsOfService = true;
			if (parseInt(this.userRequest.language) === 1) {
				window.open('https://www.thegreenshot.green/fr/termes-et-conditions', '_blank');
			} else {
				window.open('https://www.thegreenshot.green/terms-and-conditions', '_blank');
			}
		},
		handleChangeCheckTermsOfService(payload) {
			this.termsOfService = payload;
		},
		async handleSubscriptionViaLoginClose() {
			document.location.href = '/';
		},
		async handleAccountRecoveryNextStep(payload) {
			this.forgotPassword = false;
			const urlCheckStatus = await this.checkUrlParameters({
				NP: payload.xid
			});
			if (urlCheckStatus) this.$router.replace({ path: '/pages/login', query: { NP: payload.xid } });
		},
		cancelRecovery() {
			this.forgotPassword = false;
		},
		handleClickForgotPassword() {
			this.forgotPassword = true;
		},
		handleOkModalSuccessRegistration() {
			this.showModalSuccessRegistration = false;
			this.$router.push('/my-dashboard' + getParamVersion());
		},
		loginDisabled() {
			// check if password and email are entered and eventually if password and passwordVerification are equal
			let ret = true;
			const condition = this.queryType.NU
				? eval(
						'this.password.length > 0 && this.passwordVerification.length >0 && this.password == this.passwordVerification && this.email.length>0 && this.termsOfService'
				  )
				: eval('this.password.length > 0 && this.passwordVerification.length >0 && this.password == this.passwordVerification && this.email.length>0');

			if (this.askNewPassword) {
				if (condition) {
					ret = false;
				}
			} else {
				//console.log( "qsdfqsf",this.password.length)
				if (this.password.length > 0 && this.email.length > 0) {
					ret = false;
				}
			}
			return ret;
		},
		async checkUrlParameters(params = null) {
			let urlParams = this.$route.query;

			if (params !== null) {
				urlParams = params;
			} else {
				urlParams = this.$route.query;
			}

			//console.log("urlParams:",urlParams); // true

			//console.log("urlParamsurlParams.get:",urlParams.get('NU')); // "MyParam"
			let xid = null;
			if (!isNil(urlParams.NU)) {
				// console.log("urlParams:urlParams.has", urlParams.has('NU')); // true
				xid = urlParams.NU;
				this.queryType.NU = true;
				if (!isNil(xid)) {
					//  console.log("xid:",xid);
					this.userRequest = await getUserRequestInfo(xid);
					const access = (key) => {
						if (this.userRequest) {
							return this.userRequest[key];
						}
						return '';
					};
					this.email = access('email');
					this.password = '';
					if (+access('validated') === 0) {
						this.urlXid = access('xid');
						this.showRegisterOption = false;
						if (+access('userId') === 0 || +access('requestType') === 1) {
							this.askNewPassword = true;
							this.showRegisterOption = false;
							this.email = access('email');
						}
						return true;
					}
				}
			} else if (!isNil(urlParams.NP)) {
				// new password request
				xid = urlParams.NP;
				this.queryType.NP = true;
				if (!isNil(xid)) {
					this.licenseRequest = await getNewPasswordRequestInfo(xid);
					this.showRegisterOption = false;
					this.email = this.licenseRequest.email;
					if (this.licenseRequest.validated == 0 && this.licenseRequest.requestType == 1) {
						this.askNewPassword = true;
						this.urlXid = xid;
					}
				}
				return true;
			} else if (!isNil(urlParams.NL)) {
				// new license request
				xid = urlParams.NL;
				this.queryType.NL = true;
				if (!isNil(xid)) {
					var newLR = await getNewPasswordRequestInfo(xid);
					this.showRegisterOption = false;
					this.email = newLR.email;
					if (newLR.validated == 0 && newLR.requestType == 0) {
						this.askNewPassword = true;
						this.licenseRequest = newLR;
						this.urlXid = xid;
					}
				}
				return true;
			} else if (!isNil(urlParams.MS)) {
				await this.manageStoreSubscription(urlParams);
			}
			return false;
		},
		async signin() {
			this.$store.dispatch('header/setErrorAppVersion', null);
			this.$store.dispatch('header/setEmptyTokenData', false);
			if (this.askNewPassword) {
				if (this.password !== this.passwordVerification) {
					alert(this.FormMSG(1116, 'Passwords are not identical'));
					return true;
				}
			}
			this.isLoading = true;
			this.waitProcessSignin = true;

			let urXid = this.$route.query && this.$route.query.IU ? this.$route.query.IU : null;

			try {
				const signinResult = await signinService(this.email, this.password, this.urlXid, urXid, false);
				this.isLoading = false;
				if (this.licenseRequest.requestType === 0) {
					// new license request for single user or for production or for SME or for Agency
					if (this.licenseRequest.userTypeForFilm !== 5) {
						this.messageModalSuccessRegistration = this.FormMSG(201, 'Start your trial license of TheGreenShot for 15 days');
						this.showModalSuccessRegistration = true;
					} else {
						//this.startSoloModal = true;
						this.messageModalSuccessRegistration = this.FormMSG(
							202,
							'TheGreenShot: Start your FREE  license for 1 project - 1 user. Check all the features in your project configuration screen'
						);
						this.showModalSuccessRegistration = true;
					}
				}
				//console.log("aftersignin")
				const res = await store.onNewToken(this.$apollo.provider.defaultClient, signinResult.data.signIn);
				if (res) {
					this.redirectTo();
				}
			} catch (error) {
				this.isLoading = false;
				this.waitProcessSignin = false;
				if (error.networkError && error.networkError.statusCode !== 505) {
					alert(this.FormMSG(127, 'Your computer does not seem to be connected to the internet'));
				} else if (error.graphQLErrors.length > 0) {
					const status = error.graphQLErrors[0].status;
					const message = error.graphQLErrors[0].message;
					if (status >= 403 && status <= 417) {
						alert(message);
					} else {
						alert(this.FormMSG(130, 'There was an error. Please try again later: ' + JSON.stringify(error)));
					}
				}
				this.requiredAppVersion = this.getAppVersionError(error);
				this.openErrorAppVersionModal();
			}
		},
		async manageStoreSubscription(urlParams) {
			let xid = urlParams.MS;
			let resultGetLicenseRequest = null;
			let resultGetUserLastProject = null;

			if (_.isEmpty(this.getSubscriptionLicenseRequest)) {
				resultGetLicenseRequest = await getLicenseRequest(xid);
				this.updateSubscriptionLicenseRequest(resultGetLicenseRequest);
			} else {
				resultGetLicenseRequest = this.getSubscriptionLicenseRequest;
			}

			resultGetUserLastProject = await getUserLastProject(parseInt(resultGetLicenseRequest.userId));

			this.updateSubscriptionProjectId(resultGetUserLastProject.id);

			this.projectForSubscription = resultGetUserLastProject;

			this.myProfileForSubscription.id = resultGetLicenseRequest.userId;
			this.myProfileForSubscription.name = resultGetLicenseRequest.userName;
			this.myProfileForSubscription.firstName = resultGetLicenseRequest.userFirstName;
			this.myProfileForSubscription.email = resultGetLicenseRequest.email;
			this.myProfileForSubscription.language = resultGetLicenseRequest.language;

			this.showManageSubscription = true;
		},
		async redicrectFimalac() {
			const userId = store.userID();
			let data = await getValidations(4);
			let result = data !== undefined ? data.find((item) => +item.user.id === +userId) : undefined;
			if (result !== undefined) {
				return true;
			} else {
				return false;
			}
		},
		async redirectTo() {
			await isShowFimalacPlanning();
			this.resetSubscriptionData();
			if (this.$cookies.isKey('emailRegister')) {
				this.$cookies.remove('emailRegister');
			}
			if (this.queryType.NL) {
				this.$router.replace({ query: null });
			} else {
				const isFimalac = await this.redicrectFimalac();
				this.$router
					.push('/my-dashboard' + getParamVersion())
					.then((res) => {
						if (res) {
							const useFinance = store.state.myProject.useFinance;
							if (isFimalac && useFinance && HttpValidation.status) {
								this.$router.push('/time-cards/planning').catch(() => {});
							}
						}
					})
					.catch(() => {
						throw new Error(error);
					});
				return;
			}
		},
		registerNow() {
			this.$router.push('/pages/register');
		},
		getAppVersionError(error) {
			if (error.networkError && +error.networkError.statusCode === 505) {
				return error.networkError.message ? error.networkError.message.trim() : 'Error: App Version';
			}

			return null;
		},
		openErrorAppVersionModal() {
			if (!isNil(this.requiredAppVersion)) {
				this.isErrorAppVersion = true;
				this.version = this.requiredAppVersion;
				this.$store.dispatch('header/setErrorAppVersion', this.version);
				if (this.detectDevice === DEVICE.WEB) {
					this.$router.replace(`/pages/login?${getParamVersion()}`).then((res) => {
						return res;
					});
					this.$store.dispatch('header/setCountAppVersionErrorLoaded');
				}
			}
		},
		async onErrorAppVersionClosed() {
			this.isErrorAppVersion = false;
			if (this.detectDevice === DEVICE.WEB) {
				this.countAppVersionErrorLoaded = this.$store.getters['header/getCountAppVersionErrorLoaded'];
				if (this.countAppVersionErrorLoaded > 1) {
					this.isErrorAppVersion = false;
					this.$store.dispatch('header/resetCountAppVersionErrorLoaded');
					this.$router.go(0);
				}
			}
		}
	}
};
</script>

<style lang="scss">
.banner-testing-mode {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 3px;
	background-color: #f66;
}

.container-testing-mode {
	position: absolute;
	top: 3px;
	right: 0;
	padding: 15px;
	background-color: #f66;
	color: white;
	font-size: 13px;
	font-weight: bolder;
	border-radius: 0px 0px 0px 15px;
}

.card-half-left-fill {
	border-radius: 8px 0 0 8px !important;
}

.card-full-fill {
	border-radius: 8px !important;
}
</style>
